import Rive from '@rive-app/react-canvas';
import {graphql} from 'gatsby';
import React from 'react';
import Seo from '../../components/Seo/Seo';
import UniversalLink from '../../components/_baseComponents/Link/UniversalLink';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';

import UnderConstruction from '../../assets/lottie/under-construction.riv';

const MicrosoftSolutionPage = () => {
  return (
    <div
      className={`flex flex-col items-center py-[70px] text-center lg:pt-20`}
    >
      <Rive
        src={UnderConstruction}
        role="img"
        width={800}
        height={800}
        aria-label="Under construction animation"
        className="mt-5 h-[250px] w-[300px] md:h-[350px] md:w-[450px] lg:h-[500px] lg:w-[600px]"
      />
      <h1 className="mt-10 text-2xl font-bold text-primary md:text-3xl lg:text-4xl">
        Trang này đang được xây dựng
      </h1>
      <UniversalLink
        to="/"
        className={`mt-10 rounded bg-primary px-4 py-2 text-white hover:bg-primary/80 ${TailwindTransitions.default[300]}`}
      >
        Về trang chủ
      </UniversalLink>
    </div>
  );
};

export default MicrosoftSolutionPage;

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({data: {wpPage}}) => {
  return (
    <Seo
      yoastSeo={wpPage?.seo}
      title={wpPage?.title}
      uri={wpPage?.uri}
      description={wpPage?.content && wpPage.content.substring(150)}
    />
  );
};

export const pageQuery = graphql`
  query {
    wpPage(uri: {eq: "/dich-vu/giai-phap-microsoft"}) {
      id
      uri
      title
      content
      seo {
        fullHead
        canonical
      }
    }
  }
`;
